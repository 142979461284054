<template>
  <div>
    <base-header class="pb-6">
      <b-row aling-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb />
          </nav>
        </b-col>
        <b-col cols="5" lg="6" class="text-right">
          <base-button icon type="secondary" @click="onCreate">
            <span class="btn-inner--icon"><i class="ni ni-fat-add"></i></span>
            <span class="btn-inner--text">Create API Key</span>
          </base-button>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <b-card no-body>
        <b-card-header class="border-0">
          <h3 class="mb-0">API Keys</h3>
        </b-card-header>
        <el-table :data="tableData" row-key="id" header-row-class-name="thead-light">
          <el-table-column v-for="column in tableColumns" :key="column.label" v-bind="column" />
          <el-table-column prop="id">
            <template v-slot="{ row }">
              <el-dropdown trigger="click" class="dropdown">
                <i class="fas fa-ellipsis-v mt-2"></i>
                <el-dropdown-menu class="dropdown-menu show" slot="dropdown">
                  <b-dropdown-item @click="revokeToken(row)">
                    <b-icon-x-circle />
                    Revoke
                  </b-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <b-card-footer class="justify-content-center">
          <b-row>
            <b-col lg="2">
              <el-select @change="handlePerPage" class="select-primary pagination-select" v-model="pagination.perPage"
                placeholder="Per page">
                <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </b-col>
            <b-col lg="5" offset="1">
              <base-pagination align="center" class="pagination-no-border" :current="pagination.currentPage"
                :per-page="pagination.perPage" :total="pagination.total" @change="paginationChanged($event)">
              </base-pagination>
            </b-col>
            <b-col lg="4" class="text-right">
              <p class="card-category">Showing {{ from + 1 }} to {{ to }} of {{ pagination.total }} API Keys</p>
            </b-col>
          </b-row>
        </b-card-footer>
      </b-card>
    </b-container>
    <Create ref="api-key-create" />
  </div>
</template>

<script>
import swal from 'sweetalert2';
import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Option, Select } from 'element-ui';
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb.vue';
import clientPaginationMixin from '@/views/Tables/PaginatedTables/clientPaginationMixin';
import service from '@/services/settings';
import { formatISODateTime } from '@/utils/date';
import Create from './create.vue';

export default {
  name: 'SettingsAPIKeys',
  mixins: [clientPaginationMixin],
  components: {
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    RouteBreadCrumb,
    Create,
  },
  created() {
    this.getTokens();
  },
  data: () => ({
    tableColumns: [{
      prop: 'name',
      label: 'Name',
      minWidth: 240,
    }, {
      prop: 'id',
      label: 'ID',
      minWidth: 240,
    }, {
      prop: 'createdDate',
      label: 'Created Date',
      formatter: ({ createdDate }) => formatISODateTime(createdDate),
      minWidth: 200,
    }],
    tableData: [],
  }),
  methods: {
    handlePerPage(value) {
      this.pagination.perPage = value;
      this.getTokens();
    },
    paginationChanged(page) {
      this.pagination.currentPage = page;
      this.getTokens();
    },
    async getTokens() {
      const response = await service.tokens({
        size: this.pagination.perPage,
        page: this.pagination.currentPage - 1
      });
      this.pagination.total = response.total;
      this.tableData = response.results;
      console.log(this.pagination);
    },
    revokeToken(row) {
      swal.fire({
        title: 'Are you sure?',
        text: `You won't be able to revert this!`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Yes, revoke it!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          service.revokeToken(row.id).then(() => {
            this.getTokens();
            swal.fire({
              title: 'Revoke!',
              text: `You revoked ${row.name}`,
              type: 'success',
              confirmButtonClass: 'btn btn-success btn-fill',
              buttonsStyling: false
            });
          });
        }
      });
    },
    onCreate() {
      this.$refs['api-key-create'].modalOpen();
    },
  },
};
</script>
