import random from 'randomstring';
import generateApiKey from 'generate-api-key';

export default {
  randomAlphabetic(length) {
    return random.generate({
      length: length || 5,
      charset: 'alphabetic',
    });
  },
  token() {
    const b = '!v1*Q';
    const e = `${(new Date()).getTime()}`.substr(-3);
    const s = generateApiKey({
      method: 'string',
      batch: 2,
      length: 12,
    });
    const m = random.generate({
      length: 8,
      charset: 'alphabetic',
    });

    // return length = 42.
    return `${s[0]}${b}${m}.${s[1]};${e}`;
  },
};
