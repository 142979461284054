import { xenn } from './index';

export default {
  tokens(params) {
    return xenn.get('/customer-tokens', { params });
  },
  createToken(body) {
    return xenn.post('/customer-tokens', body);
  },
  revokeToken(id) {
    return xenn.delete(`/customer-tokens/${id}`);
  },
};
