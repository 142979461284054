<template>
  <modal modal-classes="modal-info" modal-content-classes="bg-gradient-info" :show.sync="showStatus" body-classes="p-0">
    <div class="p-4">
      <div v-if="!showSuccess">
        <base-input v-model="name" rules="required" label="API Key Name" name="API Key Name" placeholder="Platform X" />
      </div>
      <div v-else>
        <div class="wrapper d-flex">
          <div class="px-2">
            <b-iconstack font-scale="5" variant="white">
              <b-icon stacked icon="square-fill" variant="dark" />
              <b-icon stacked icon="key-fill" scale="0.75" />
            </b-iconstack>
          </div>
          <div class="px-2">
            <p class="title">API Key Created</p>
            <p>Please copy this key and save it somewhere safe.</p>
            <p class="warning">For security reasons, we cannot show it to you again.</p>
          </div>
        </div>
        <p class="token mt-4">{{ token }}</p>
      </div>
    </div>
    <template slot="footer">
      <template v-if="!showSuccess">
        <b-button variant="secondary" @click="closeModal">Cancel</b-button>
        <b-button variant="primary" @click="createToken" :disabled="!name.length">Create</b-button>
      </template>
      <template v-else>
        <b-button variant="secondary" @click="closeModal">Close</b-button>
        <b-button variant="primary" @click="copyToken">Copy</b-button>
      </template>
    </template>
  </modal>
</template>

<script>
import { Modal } from '@/components';
import service from '@/services/settings';
import randomUtils from '@/utils/randomUtils';

export default {
  name: 'SegmentModal',
  components: {
    Modal,
  },
  data: () => ({
    showStatus: false,
    token: '',
    name: '',
    showSuccess: false,
  }),
  methods: {
    modalOpen() {
      this.showStatus = true;
      this.showSuccess = false;
      this.name = '';
      this.generateToken();
    },
    closeModal() {
      this.showStatus = false;
    },
    generateToken() {
      this.token = randomUtils.token();
    },
    createToken() {
      service.createToken({
        name: this.name,
        token: this.token,
      }).then(() => {
        this.showSuccess = true;
      }).catch(() => {
        this.generateToken();
        this.createToken();
      });
    },
    copyToken() {
      navigator.clipboard.writeText(this.token);

      this.$bvToast.toast('The key copied successfully!', {
        title: 'API Key',
        autoHideDelay: 3000,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 0;
  font-size: 14px;

  &.title {
    font-weight: bold;
    font-size: 16px;
  }

  &.warning {
    color: #e23d75;
    font-style: italic;
    font-weight: 500;
  }

  &.token {
    font-size: 16px;
    background-color: #ddd;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    overflow-wrap: break-word;
  }
}
</style>
